
import { computed, reactive, watch, ref, defineComponent } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import CreateOrUpdate from './components/CreateOrUpdate.vue'
import { imageUrl } from '@/utils/image'
import useImageLibraryRepositories from '@/repositories/useImageLibraryRepositories'
import { useNotification } from '@/composables'
import { formatTime } from '@/utils/dateTime'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import { CopyDocument } from '@element-plus/icons'
import { copyToClipboardV2 } from '@/utils/string'
import { isAgencyManager } from '@/utils/orderUtils'

export default defineComponent({
  components: {
    sectionLayoutContent,
    CreateOrUpdate,
    FilterTable,
    Pagination,
    CopyDocument,
  },
  setup() {
    const breadcrumbs = ['Image Library', '']
    const title = 'Shops'
    const baseResource = 'images'

    const titleCreate = 'Upload image'
    const iconCreate = 'fas fa-cloud-upload-alt'

    const content = computed(() => {
      return { breadcrumbs, title, titleCreate, iconCreate }
    })

    const { del } = useImageLibraryRepositories()
    const { success, error } = useNotification()

    const onDelete = async (row: any) => {
      if (row && row._id) {
        const res = await del(row._id)
        if (res?.status === 200 || res?.status === 201) {
          success('Delete success')
          fetchData()
        } else {
          error('Delete fail')
        }
      }
    }

    const isAllowDeleteImage = ref(false)
    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
      isAllowDeleteImage.value = isAgencyManager(userInfo)
    } catch (e) {
      return error("Can't find user info")
    }

    const onFilterCallback = (filter: any) => {
      params.search = filter.search
    }

    let loading = ref(false)

    const params = reactive({ search: '', page: 1, limit: 10 })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    loading = isLoading
    fetchData()

    watch(params, fetchData)

    const onAfterCreate = () => {
      fetchData()
    }

    const columns = [
      {
        key: 'id',
        title: 'Title',
        dataIndex: 'title',
        minWidth: '30',
      },
      {
        title: 'Preview',
        dataIndex: 'filePath',
        minWidth: '15',
        // align: 'center',
      },
      {
        title: 'Type',
        dataIndex: 'contentType',
        minWidth: '10',
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: '10',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        minWidth: '15',
        align: 'center',
      },
    ]

    const uploadLibraryImageDialog = ref<InstanceType<typeof CreateOrUpdate>>()
    const createImageLibrary = () => {
      uploadLibraryImageDialog.value?.toggle('')
    }

    const updateImageLibrary = (entity: any) => {
      uploadLibraryImageDialog.value?.toggle(entity.id)
    }

    return {
      params,
      content,
      documents,
      isLoading,
      loading,
      total,
      columns,
      uploadLibraryImageDialog,
      createImageLibrary,
      updateImageLibrary,
      onAfterCreate,
      imageUrl,
      onDelete,
      formatTime,
      onFilterCallback,
      copyToClipboardV2,
      isAllowDeleteImage,
    }
  },
})
