
import { defineComponent, reactive, ref } from 'vue'
import useUploadRepositories from '@/repositories/useUploadRepositories'
import useImageLibraryRepositories from '@/repositories/useImageLibraryRepositories'
import { useNotification } from '@/composables'
import {
  validateImageFileUploaded,
  getFileNameFromUrl,
  getBaseFileName,
} from '@/utils/file'
import { imageUrl, checkImageLinkLattePrint } from '@/utils/image'
import { UploadFilled, Refresh, View, CloseBold } from '@element-plus/icons'
import PreviewImage from '@/components/common/PreviewImage.vue'

export default defineComponent({
  components: { UploadFilled, Refresh, View, PreviewImage, CloseBold },
  setup(props, context) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    const libraryImageForm = ref()

    const uploadedFile = ref<any>(null)

    const imageId = ref('')
    const type = ref('')

    const baseImage = ref('')

    const { getOne, edit, create } = useImageLibraryRepositories()

    const state = reactive({
      form: {
        title: '',
        filePath: '',
        contentType: '',
      },
    })
    const onUploadDesign = (file: any) => {
      if (file && validateImageFileUploaded(file)) {
        state.form.filePath = window.URL.createObjectURL(file.raw)
        state.form.title = getBaseFileName(
          getFileNameFromUrl(file.name) as any
        ) as any
        uploadedFile.value = file
      }
    }

    const onClearUploadedFile = () => {
      state.form.filePath = baseImage.value
      uploadedFile.value = null
    }

    const onDeleteUploadedFile = () => {
      state.form.filePath = ''
      uploadedFile.value = null
    }

    const { success, error, warning } = useNotification()
    const { onUploadImage } = useUploadRepositories()

    const onSubmit = async () => {
      console.log('1111', state.form.filePath)

      if (state.form.title?.length <= 0) {
        warning('Please input title!')
        return
      }

      if (
        !uploadedFile.value &&
        !checkImageLinkLattePrint(state.form.filePath)
      ) {
        warning('Please upload your image!')
        return
      }

      if (uploadedFile.value?.raw) {
        if (uploadedFile.value.raw?.type) {
          state.form.contentType = uploadedFile.value.raw.type
        }
        const uploadImageRes = await onUploadImage(uploadedFile.value.raw)

        if (uploadImageRes?.path) {
          state.form.filePath = uploadImageRes.path
        } else {
          error('Upload image fail')
          return
        }
      }

      const formData = {
        title: state.form.title,
        filePath: state.form.filePath,
        contentType: state.form.contentType,
      }

      let res
      if (type.value === 'create') {
        res = await create(formData)
      } else if (type.value === 'edit') {
        if (imageId.value?.length > 0) res = await edit(imageId.value, formData)
      }
      if (res && (res.status === 200 || res.status === 201)) {
        success('Upload image library success!')
        close()
        context.emit('onAfterCreate')
      } else {
        error('Error when save image library')
      }
    }

    const fetchRecord = async () => {
      loading.value = true
      let res = (await getOne(imageId.value)) as any
      if (res.status === 200 || res.status === 201) {
        state.form = res.data as any
        baseImage.value = state.form.filePath
      }
      loading.value = false
    }

    const toggle = (id: string) => {
      dialogVisible.value = !dialogVisible.value
      imageId.value = id
      if (!id || id === '') {
        type.value = 'create'
      } else {
        type.value = 'edit'
        fetchRecord()
      }
    }

    const close = () => {
      libraryImageForm.value?.resetFields()
      dialogVisible.value = false
    }

    const afterClose = () => {
      console.log('afterLeave')
      state.form = {
        title: '',
        filePath: '',
        contentType: '',
      }
    }

    const previewImageDialog = ref<InstanceType<typeof PreviewImage>>()
    const onClickPreviewImage = () => {
      previewImageDialog.value?.toggle(state.form.filePath)
    }

    return {
      state,
      dialogVisible,
      loading,
      toggle,
      libraryImageForm,
      onSubmit,
      onUploadImage,
      getOne,
      edit,
      create,
      imageId,
      type,
      fetchRecord,
      close,
      onClearUploadedFile,
      onUploadDesign,
      imageUrl,
      onClickPreviewImage,
      previewImageDialog,
      afterClose,
      baseImage,
      onDeleteUploadedFile,
    }
  },
})
